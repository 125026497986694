import React from 'react'
import { Link } from 'gatsby'
import ProjectImage from './project-image'
import * as styles from './project-thumbs.module.scss'

export default function ProjectThumbs({ projects }) {
  return (
    <>
      <div className="px-2 py-20 md:py-0">
        {projects.map((project) => {
          const { id, images, slug, title } = project
          return (
            <article id={slug} className={`project-thumb pb-16 md:py-20 md:pb-6 text-center ${styles.projectThumb}`} key={id}>
              <div className='flex flex-col justify-center h-full w-full'>
                <Link
                  to={`/projects/` + slug}
                  className={`mb-4 flex ${styles.projectImageLink}`}
                >
                  <ProjectImage
                    image={images[0]}
                    size={'md'}
                  />
                </Link>
                <h2 className="text-2xl md:text-3xl font-display font-bold text-center uppercase">
                  <Link to={`/projects/` + slug} className="hover:text-black">
                    {title}
                  </Link>
                </h2>
              </div>
            </article>
          )
        })}
      </div>
    </>
  )
}

import React from 'react'
import '../styles/global.scss'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { graphql } from 'gatsby'
import ProjectThumbs from '../components/project-thumbs'

export default function Index({ data }) {
  const projects = data.contentfulProjectGallery.projects
  return (
    <Layout classes={'max-w-6xl mx-auto lg:px-4'}>
      <Seo title="Home" />
      <div
        id={'homeSplash'}
        className="fixed opacity-0 left-0 top-0 w-screen h-screen bg-white flex items-center justify-center"
      >
        <p
          className={'text-3xl md:text-5xl lg:text-6xl font-display font-bold'}
        >
          TOUSAS
        </p>
      </div>
      <div id="homeContent" className="relative">
        <ProjectThumbs projects={projects} />
      </div>
    </Layout>
  )
}

// @todo: check for all types of images
export const query = graphql`
  query allProjects {
    contentfulProjectGallery(galleryTitle: { eq: "Homepage Gallery" }) {
      projects {
        id
        slug
        title
        images {
          id
          gatsbyImageData
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
        }
      }
    }
  }
`
